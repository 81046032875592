<template>
  <div style="padding:20px;">
    <div class="item">
      <h2 class="title">什么是财富值？</h2>
      <p>用户赠送一定价值的礼物（幸运礼物除外）即可获得一定的财富值，<span>赠送</span>1金币（<img src="img/bi.png" alt="">）礼物=<span>增加</span>1点财富值</p>
    </div>
    <div class="item">
      <h2 class="title">什么是魅力值？</h2>
      <p>用户收到一定价值的礼物（幸运礼物除外），即可获得此礼物同等价值的魅力值，<span>收到</span>1金币（<img src="img/bi.png" alt="">）礼物=<span>增加</span>1点魅力值</p>
    </div>
    <div class="item">
      <h2 class="title">什么是财富等级？</h2>
      <p>财富等级是您在平台尊贵身份的象征，当<span>财富值</span>累积到一定数值后，即可升级到下一等级，并且拥有全新的财富等级徽章</p>
    </div>
    <div class="item">
      <h2 class="title">什么是魅力等级？</h2>
      <p>魅力等级是您在平台崇高人气的象征，当<span>魅力值</span>累积到一定数值后，即可升级到下一等级，并且拥有全新的魅力等级徽章</p>
    </div>
    <div class="item">
      <h2 class="title">关于幸运礼物</h2>
      <p><span>赠送</span>或<span>收到</span>幸运礼物，可获得此幸运礼物<span>金币</span>价值的<span>10%财富值或魅力值</span></p>
    </div>
    <div class="item">
      <h2 class="title">如何升级？</h2>
      <p>参与房间互动，送礼物打赏主播或收到其他玩家赠送的礼物，均可提升个人财富等级或魅力等级</p>
    </div>
    
  </div>
</template>

<script>
// import {appActivityCreate} from "@/api/ranking";

export default {
  name: 'upgrade',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item{
  padding: 25px 0 0;
  border-bottom: 1px solid #f2f2f2;
}
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    color: rgba(0,0,0,.8);
}
span{
  color: red;
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
    text-indent: unset;
    padding: 0;
    margin: 0;
    height: auto;
}
img{
  vertical-align: middle;
  width: 40px;
}
</style>
